// app/providers.js
'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React, { useEffect } from 'react'; // Add the missing import statement for React
if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  console.log('Initializing PostHog');
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  });
} else {
  console.log('PostHog not initialized');
}
export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  // Specify the type for the 'children' prop
  return (
    <PostHogProvider client={posthog}>
      <CalendlyEventsProvider>{children}</CalendlyEventsProvider>
    </PostHogProvider>
  );
}

export function CalendlyEventsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  useEffect(() => {
    function isCalendlyEvent(e: MessageEvent) {
      return e.data.event && e.data.event.includes('calendly');
    }

    async function handlePostMessage(e: MessageEvent) {
      if (isCalendlyEvent(e)) {
        console.log(e.data);
        posthog.capture(e.data.event, e.data.payload);
        const { done } = await fetch('/api/calendly', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(e.data),
        }).then((res) => res.json());
        if (done) {
          console.log('Posthog identify done');
        }
      }
    }

    window.addEventListener('message', handlePostMessage);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  return <>{children}</>;
}
